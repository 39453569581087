import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import {
    DialogBadge,
    DialogTopic,
    ISearchOperatorsByCriterionRequest,
    ISearchOperatorsByCriterionResponse,
    ReadonlyChat
} from "../../models/Dialogs/dialog"
import {
    ApiMessagesDU,
    GetUpdatedMessagesRequest,
    MessageAttachment,
    MessageMeta,
    Sticker,
    UpdatedMessage
} from "../../models/Dialogs/message"
import { SystemError } from "../../core/error"
import { LegacyDialog } from "../../models/Dialogs/legacyDialog"
import { SurveyForm } from "../../models/Dialogs/surveyForm"
import { convertDialogTopicsToTree, getListOfSelectedIds } from "../../utility/dialogs/dialog-topics"
import { IAISuggestItemResponse } from "../../models/Dialogs/aiSuggestDTOs"
import { resetReducerState } from "../action"
import { ChannelTypeString, SimpleChannel, SimpleChannelWithUserId } from "../../models/channel"
import { ContentEditorCommands } from "../../components/ContentEditor/ContentEditor"
import { dialogDraftsLocalStoreDriver } from "../../api/instances/dialogDraftsLocalStoreDriver"

export interface DialogTopicsTreeData {
    rootId: string
    items: Record<string, DialogTopicsTreeItem>
}
export interface DialogTopicsTreeItemData {
    isSelected: boolean
    parentId: string
    title: string
}
export interface DialogTopicsTreeItem {
    id: string
    children: string[]
    hasChildren?: boolean
    isExpanded?: boolean
    isChildrenLoading?: boolean
    data: DialogTopicsTreeItemData
}

export interface DialogEmailData {
    subject: string
    attachments: MessageAttachment[]
    emailAnswerMode: EmailAnswerMode
}

export enum EmailAnswerMode {
    None = "",
    Reply = "Reply",
    Forward = "Forward"
}

export interface IAIAssistHint {
    alarm: boolean
    message: string
    supportArticles: Array<Record<string, string>>
}

export type EditMessageData = {
    id: string
    attachments?: MessageAttachment[]
    sticker?: Sticker
    text?: string
}

export interface DialogOutgoingData {
    channel: SimpleChannel
    channelUserId: string
}

export type DialogsSlice = Readonly<{
    aiAssistHint: AsyncState<IAIAssistHint>
    aiSuggest: AsyncState<IAISuggestItemResponse[]>
    aiAssistRequestTypes: AsyncState<string[]>
    current: AsyncState<LegacyDialog>
    messages: AsyncState<UpdatedMessage[]>
    survey: AsyncState<SurveyForm>
    selectedDialogId?: string
    selectedOperatorClientId?: string
    messagesPaginationState?: GetUpdatedMessagesRequest
    searchCriterion?: string
    searchOperatorByCriterionResponse: ISearchOperatorsByCriterionResponse[]
    searchOperatorByCriterionPrevArgs: ISearchOperatorsByCriterionRequest
    topics: {
        isTopicsSidebarOpened: boolean
        treeData: DialogTopicsTreeData
    }
    /*
      Сейчас у нас целых 5 стейтов для сообщения
      inputMessage, inputDrafted - redux
      dialogDraftsLocalStoreDriver - localStorage
      editorContent -  в DialogMessageInput
      text - в формике в DialogMessageInput
      Также в DialogContentEditor есть странный хак с обновлением стейта
      Все эти стейты взаимосвязаны, делают похожие вещи и влияют друг на друга, что приводит к багам

      TODO: Оставить 2 стейта
      - для сохранения стейта инпута локально при перезагрузке страницы (можно оставить текущий из localStorage)
      - для локального хранения стейта инпута
    */
    inputMessage: string
    inputDrafted: string
    inputLastCursorPosition: number
    shouldHandleCursorPosition: boolean
    knowledgeBase: {
        isKnowledgeBaseSidebarOpened: boolean
        isArticleSidebarOpened: boolean
    }
    fastReplies: {
        isFastRepliesSidebarOpened: boolean
    }
    dialogCreate: {
        creatingDialog: boolean
        isChannelSelectSidebarOpened: boolean
        isCreatingClientSidebarOpened: boolean
        dialogCreateOutgoingData: DialogOutgoingData | null
    }
    searchData: DialogBadge[]
    lastMessageChannel: SimpleChannel
    lastMessageInDialog?: ApiMessagesDU
    inputEditMessageData?: EditMessageData
    readonlyChat: ReadonlyChat
    isInputMaximized: boolean
    unsavedSurveys: string[]
    RMECommands: ContentEditorCommands | null
    dialogEmailData: DialogEmailData
    currentOmniUserId: string
    dialogEmailMessageMeta: MessageMeta
    byLink: {
        channelId: string
        omniUserId: string
        testPassed: boolean
    }
}>

const initTopicsTree: DialogTopicsTreeData = {
    rootId: "root",
    items: {
        root: {
            id: "root",
            children: [],
            data: {
                parentId: "",
                title: "",
                isSelected: false
            }
        }
    }
}

const initialState: DialogsSlice = {
    aiAssistHint: AsyncState.create(),
    aiSuggest: AsyncState.create(),
    aiAssistRequestTypes: AsyncState.create(),
    current: AsyncState.create(),
    messages: AsyncState.create(),
    survey: AsyncState.create(),
    searchOperatorByCriterionPrevArgs: {
        query: "",
        statuses: [],
        queues: [],
        roles: [],
        includeCurrentUser: false
    },
    searchOperatorByCriterionResponse: [],
    topics: {
        isTopicsSidebarOpened: false,
        treeData: initTopicsTree
    },
    inputMessage: "",
    inputDrafted: "",
    inputLastCursorPosition: 0,
    shouldHandleCursorPosition: false,
    knowledgeBase: {
        isKnowledgeBaseSidebarOpened: false,
        isArticleSidebarOpened: false
    },
    fastReplies: {
        isFastRepliesSidebarOpened: false
    },
    dialogCreate: {
        creatingDialog: false,
        isChannelSelectSidebarOpened: false,
        isCreatingClientSidebarOpened: false,
        dialogCreateOutgoingData: null
    },
    searchData: [],
    lastMessageChannel: {
        Id: "",
        Type: ChannelTypeString.Unknown,
        Title: ""
    },
    lastMessageInDialog: undefined,
    inputEditMessageData: undefined,
    readonlyChat: null,
    isInputMaximized: false,
    unsavedSurveys: [],
    RMECommands: null,
    dialogEmailData: {
        subject: "",
        attachments: [],
        emailAnswerMode: EmailAnswerMode.None
    },
    currentOmniUserId: "",
    dialogEmailMessageMeta: {},
    byLink: {
        channelId: "",
        omniUserId: "",
        testPassed: false
    }
}

const dialogs = createSlice({
    name: "dialogs",
    initialState,
    reducers: {
        getAIAssistHintProcess(state) {
            state.aiAssistHint = state.aiAssistHint.toProcess()
        },
        getAIAssistHintSuccess(state, action: PayloadAction<IAIAssistHint>) {
            state.aiAssistHint = state.aiAssistHint.toSuccess(action.payload)
        },
        getAIAssistHintFailed(state, action: PayloadAction<SystemError>) {
            state.aiAssistHint = state.aiAssistHint.toFailed(action.payload)
        },
        getAIAssistHintReset(state) {
            state.aiAssistHint = AsyncState.create()
        },
        getAISuggestProcess(state) {
            state.aiSuggest = state.aiSuggest.toProcess()
        },
        getAISuggestSuccess(state, action: PayloadAction<IAISuggestItemResponse[]>) {
            state.aiSuggest = state.aiSuggest.toSuccess(action.payload)
        },
        getAISuggestFailed(state, action: PayloadAction<SystemError>) {
            state.aiSuggest = state.aiSuggest.toFailed(action.payload)
        },
        getAISuggestReset(state) {
            state.aiSuggest = AsyncState.create()
        },
        getAIAssistRequestTypesProcess(state) {
            state.aiAssistRequestTypes = state.aiAssistRequestTypes.toProcess()
        },
        getAIAssistRequestTypesSuccess(state, action: PayloadAction<string[]>) {
            state.aiAssistRequestTypes = state.aiAssistRequestTypes.toSuccess(action.payload)
        },
        getAIAssistRequestTypesFailed(state, action: PayloadAction<SystemError>) {
            state.aiAssistRequestTypes = state.aiAssistRequestTypes.toFailed(action.payload)
        },
        setCursorPosition(state, action: PayloadAction<number>) {
            state.inputLastCursorPosition = action.payload
        },
        setCursorPositionWithTextInserted(state, action: PayloadAction<{ textLength: number; lastPosition: number }>) {
            state.inputLastCursorPosition = Math.min(
                action.payload.textLength + state.inputLastCursorPosition,
                action.payload.lastPosition
            )
            state.shouldHandleCursorPosition = true
        },
        resetShouldHandleCursorPosition(state) {
            state.shouldHandleCursorPosition = false
        },
        getCurrentDialogProcess(state) {
            state.current = state.current.toProcess()
        },
        getCurrentDialogSuccess(state, action: PayloadAction<LegacyDialog>) {
            state.current = state.current.toSuccess(action.payload)
        },
        getCurrentDialogFailed(state, action: PayloadAction<SystemError>) {
            state.current = state.current.toFailed(action.payload)
        },
        getDialogMessagesProcess(state) {
            state.messages = state.messages.toProcess()
        },
        getDialogMessagesSuccess(state, action: PayloadAction<UpdatedMessage[]>) {
            state.messages = state.messages.toSuccess(action.payload)
        },
        getDialogMessagesFailed(state, action: PayloadAction<SystemError>) {
            state.messages = state.messages.toFailed(action.payload)
        },
        setCurrentOperatorClientId(state, action: PayloadAction<string>) {
            state.dialogCreate.creatingDialog = false
            state.dialogCreate.isCreatingClientSidebarOpened = false
            state.dialogCreate.isChannelSelectSidebarOpened = false
            state.dialogCreate.dialogCreateOutgoingData = initialState.dialogCreate.dialogCreateOutgoingData
            state.selectedOperatorClientId = action.payload
        },
        unsetCurrentOperatorClientId(state) {
            state.selectedOperatorClientId = undefined
        },
        setCurrentDialogId(state, action: PayloadAction<string>) {
            state.dialogCreate.creatingDialog = false
            state.dialogCreate.isCreatingClientSidebarOpened = false
            state.dialogCreate.isChannelSelectSidebarOpened = false
            state.dialogCreate.dialogCreateOutgoingData = initialState.dialogCreate.dialogCreateOutgoingData
            state.selectedDialogId = action.payload
        },
        unsetCurrentDialogId(state) {
            state.selectedDialogId = undefined
        },
        setSearchCriterion(state, action: PayloadAction<string>) {
            state.searchCriterion = action.payload
        },
        getClientSurveyProcess(state) {
            state.survey = state.survey.toProcess()
        },
        getClientSurveySuccess(state, action: PayloadAction<SurveyForm>) {
            state.survey = state.survey.toSuccess(action.payload)
        },
        getClientSurveyFailed(state, action: PayloadAction<SystemError>) {
            state.survey = state.survey.toFailed(action.payload)
        },
        setMessagesPaginationState(state, action: PayloadAction<Partial<GetUpdatedMessagesRequest>>) {
            const newState = {
                ...state.messagesPaginationState,
                ...action.payload
            } as GetUpdatedMessagesRequest
            state.messagesPaginationState = newState
        },
        setSearchOperatorByCriterionRelatedData(
            state,
            action: PayloadAction<{
                args: ISearchOperatorsByCriterionRequest
                response: ISearchOperatorsByCriterionResponse[]
            }>
        ) {
            state.searchOperatorByCriterionPrevArgs = {
                ...state.searchOperatorByCriterionPrevArgs,
                ...action.payload.args
            }
            state.searchOperatorByCriterionResponse = action.payload.response
        },
        resetSearchOperatorByCriterionRelatedData(state) {
            state.searchOperatorByCriterionPrevArgs = { ...initialState.searchOperatorByCriterionPrevArgs }
            state.searchOperatorByCriterionResponse = []
        },
        openTopicsSidebar(state) {
            state.topics.isTopicsSidebarOpened = true
        },
        closeTopicsSidebar(state) {
            state.topics.isTopicsSidebarOpened = false
            const { treeData } = state.topics
            const { items, rootId } = treeData
            const treeDataIds = Object.keys(items).filter(itemId => itemId !== rootId)

            treeDataIds.forEach(topicId => (items[topicId].data.isSelected = false))
        },
        setTopicsData(state, action: PayloadAction<DialogTopic[]>) {
            state.topics.treeData = convertDialogTopicsToTree(action.payload)
        },
        openKnowledgeBaseSidebar(state) {
            state.knowledgeBase.isKnowledgeBaseSidebarOpened = true
        },
        closeKnowledgeBaseSidebar(state) {
            state.knowledgeBase.isKnowledgeBaseSidebarOpened = false
        },
        openFastRepliesSidebar(state) {
            state.fastReplies.isFastRepliesSidebarOpened = true
        },
        closeFastRepliesSidebar(state) {
            state.fastReplies.isFastRepliesSidebarOpened = false
        },
        openArticleSidebar(state) {
            state.knowledgeBase.isArticleSidebarOpened = true
        },
        closeArticleSidebar(state) {
            state.knowledgeBase.isArticleSidebarOpened = false
        },
        selectAndExpandDialogTopic(state, action: PayloadAction<DialogTopicsTreeItem>) {
            const { treeData } = state.topics
            const { items, rootId } = treeData

            const treeDataIds = Object.keys(items).filter(itemId => itemId !== rootId)
            const selectedIds = getListOfSelectedIds(treeData, action.payload)

            treeDataIds.forEach(topicId => {
                const topic = items[topicId]
                if (selectedIds.includes(topicId)) {
                    topic.data.isSelected = true
                    topic.isExpanded = topicId === action.payload.id ? !topic.isExpanded : true
                } else {
                    topic.data.isSelected = false
                }
            })
        },
        clearMessageInput(state) {
            state.inputMessage = initialState.inputMessage
        },
        setMessageInput(state, action: PayloadAction<string>) {
            state.inputMessage = action.payload
        },
        createDialog(state) {
            state.dialogCreate.creatingDialog = true
            state.dialogCreate.isChannelSelectSidebarOpened = true
        },
        finishCreatingDialog(state) {
            state.dialogCreate.creatingDialog = false
            state.dialogCreate.isCreatingClientSidebarOpened = false
            state.dialogCreate.isChannelSelectSidebarOpened = false
            state.dialogCreate.dialogCreateOutgoingData = initialState.dialogCreate.dialogCreateOutgoingData
        },
        openCreateClientSidebar(state) {
            state.dialogCreate.isCreatingClientSidebarOpened = true
        },
        closeCreateClientSidebar(state) {
            state.dialogCreate.isCreatingClientSidebarOpened = false
        },
        openChannelSelectSidebar(state) {
            state.dialogCreate.isChannelSelectSidebarOpened = true
        },
        closeChannelSelectSidebar(state) {
            state.dialogCreate.isChannelSelectSidebarOpened = false
        },
        setDialogOutgoingData(state, action: PayloadAction<DialogOutgoingData | null>) {
            state.dialogCreate.dialogCreateOutgoingData = action.payload
        },
        setLastMessageChannel(state, action: PayloadAction<SimpleChannelWithUserId>) {
            state.lastMessageChannel = action.payload
        },
        setLastMessageInDialog(state, action: PayloadAction<ApiMessagesDU>) {
            state.lastMessageInDialog = action.payload
        },
        setDraftedInput(state, action: PayloadAction<string>) {
            state.inputDrafted = action.payload
        },
        setEditMessageMode(state, action: PayloadAction<EditMessageData>) {
            state.inputEditMessageData = action.payload
        },
        offEditMessageMode(state) {
            state.inputEditMessageData = undefined
        },
        setInputMaximized(state, action: PayloadAction<boolean>) {
            state.isInputMaximized = action.payload
        },
        setRMECommands(state, action: PayloadAction<ContentEditorCommands>) {
            state.RMECommands = action.payload
        },
        setDialogEmailData(state, action: PayloadAction<DialogEmailData>) {
            state.dialogEmailData = action.payload
        },
        clearDialogEmailData(state) {
            state.dialogEmailData = initialState.dialogEmailData
        },
        setReadonlyChat(state, action: PayloadAction<ReadonlyChat>) {
            state.readonlyChat = action.payload
        },
        setCurrentOmniUserId(state, action: PayloadAction<string>) {
            state.currentOmniUserId = action.payload
        },
        setOmniUserIdByLink(state, action: PayloadAction<string>) {
            state.byLink.omniUserId = action.payload
        },
        setTestPassedByLink(state, action: PayloadAction<boolean>) {
            state.byLink.testPassed = action.payload
        },
        setChannelIdByLink(state, action: PayloadAction<string>) {
            state.byLink.channelId = action.payload
        },
        setSearchData(state, action: PayloadAction<DialogBadge[]>) {
            state.searchData = action.payload
        },
        setDialogMessageEmailMeta(state, action: PayloadAction<MessageMeta>) {
            state.dialogEmailMessageMeta = action.payload
        },
        toggleUnsavedSurvey(state, { payload: [surveyId, isUnsaved] }: PayloadAction<[string, boolean]>) {
            state.unsavedSurveys = state.unsavedSurveys.filter(id => id !== surveyId)
            if (isUnsaved) {
                state.unsavedSurveys.push(surveyId)
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(resetReducerState, () => {
                return initialState
            })
            .addMatcher(
                action => action.type.endsWith("setDraftedInput"),
                (state, action) => {
                    const draftEntityId = state.selectedDialogId ?? state.selectedOperatorClientId

                    if (!draftEntityId) {
                        return
                    }

                    if (action.payload) {
                        dialogDraftsLocalStoreDriver.insert(draftEntityId, action.payload)
                    } else {
                        dialogDraftsLocalStoreDriver.remove(draftEntityId)
                    }
                }
            )
    }
})

export const DialogsReducer = dialogs.reducer
export const { actions } = dialogs
