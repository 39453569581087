import {
    CreateQueueRequest,
    ExtendQueueSettings,
    MonitoringOverview,
    Queue,
    QueuesByTenantDtoResponse,
    UpdateIndividualQueuesRequest,
    UpdateQueueRequest
} from "../../models/queue"
import { apiInstance } from "../instances/apiInstance"
import {
    CreateQueueCategoryRequest,
    CreateQueueCategoryResponse,
    GetQueueCategoriesResponse,
    MoveQueueRequest,
    MoveQueueResponse,
    UpdateQueueCategoryRequest,
    UpdateQueueCategoryResponse
} from "../../models/queueCategory"
import { processUser } from "../../utility/common/processUser"
import { QueueSearchRequest, QueueSearchResponse } from "../../models/queueSearch"
import { mapConverters } from "../../utility/common/mapConverters"

const queuesController = {
    getByTenant: (tenantId: string): Promise<QueuesByTenantDtoResponse> =>
        apiInstance.get(`/${tenantId}/queues`).then(response => response.data),
    getMonitoringOverview: (tenantId: string): Promise<MonitoringOverview> =>
        apiInstance.get(`/${tenantId}/monitoring-overview`).then(response => response.data),
    create: (tenantId: string, request: CreateQueueRequest): Promise<Queue> =>
        apiInstance.post(`/${tenantId}/queues`, request).then(response => response.data),
    update: (tenantId: string, id: string, request: UpdateQueueRequest): Promise<Queue> =>
        apiInstance.put(`/${tenantId}/queues/${id}`, request).then(response => response.data),
    updateIndividual: (tenantId: string, id: string, request: UpdateIndividualQueuesRequest): Promise<void> =>
        apiInstance.put(`/${tenantId}/queues/${id}/individual`, request).then(response => response.data),
    delete: (tenantId: string, categoryId: string, queueId: string): Promise<void> =>
        apiInstance
            .delete(`/${tenantId}/queues/categories/${categoryId}/queue/${queueId}`)
            .then(response => response.data),
    search: (tenantId: string, request: QueueSearchRequest): Promise<QueueSearchResponse> =>
        apiInstance
            .post(`/${tenantId}/queues/search`, request)
            .then(response => response.data)
            .then(data => ({
                ...data,
                Operators: data.Operators.map(processUser)
            })),
    getCategories: (tenantId: string): Promise<GetQueueCategoriesResponse> =>
        apiInstance.get(`/${tenantId}/queues/categories`).then(response => {
            response.data.AllCategories = response.data.AllCategories.map(mapConverters.taskConverter.mapCategories)

            return response.data
        }),
    removeCategory: (tenantId: string, categoryId: string): Promise<void> =>
        apiInstance
            .delete(`/${tenantId}/queues/categories/${categoryId}`, { timeout: 120000 })
            .then(response => response.data),
    updateCategory: (
        tenantId: string,
        categoryId: string,
        request: UpdateQueueCategoryRequest
    ): Promise<UpdateQueueCategoryResponse> =>
        apiInstance.put(`/${tenantId}/queues/categories/${categoryId}`, request).then(response => response.data),
    createCategory: (tenantId: string, request: CreateQueueCategoryRequest): Promise<CreateQueueCategoryResponse> =>
        apiInstance.post(`/${tenantId}/queues/categories`, request).then(response => response.data),
    moveQueue: (tenantId: string, categoryId: string, request: MoveQueueRequest): Promise<MoveQueueResponse> =>
        apiInstance
            .post(`/${tenantId}/queues/categories/${categoryId}/add-queues`, request)
            .then(response => response.data),
    getQueueExtendedSettings: (projectId: string, queueId: string): Promise<ExtendQueueSettings> =>
        apiInstance.get(`/${projectId}/queues/extend-settings?queueId=${queueId}`).then(response => response.data)
}

export default queuesController
